<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Teams from "@/services/Teams";
// import modalEditTeam from "@/components/modals/teams/modalEditTeam";
import modalEditTeamCompetition from "@/components/modals/teams/modalEditTeamCompetition";
import Swal from "sweetalert2";

/**
 * Orders component
 */
export default {
  components: { Layout, PageHeader,  modalEditTeamCompetition},
  page: {
    title: "Teams",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      modalData:{
        team_id: null,
        ticketing_url: null,
        ticketingAttribute: null
      },
      showModal:false,
      competition_id: process.env.VUE_APP_COMPETITION_ID,
      title: "Teams",
      items: [
        {
          text: "Teams",
        },
        {
          text: "List",
          active: true,
        },
      ],
      tableData: [],
      competition_name: null,
      competition_start: null,
      competition_end: null,
      season: null,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      competitions: [], // Array to store competitions
      selectedCompetition: null, // Selected competition
      competitionLength: 0,
      teams: [], // All teams data
      fields: [
        {
          key: "team_id",
          label: "Team ID",
        },
        {
          key: "team_logo",
          label: "Team Logo",
          sortable: true,
        },
        {
          key: "team_name",
          label: "Team Name",
          sortable: true,
        },
        {
          key: "team_code",
          label: "Team Code",
          sortable: true,
        },
        {
          key: "team_country",
          label: "Team Country",
          sortable: true,
        },
        {
          key: "ticketing_url",
          label: "Ticketing URL",
          sortable: true,
        },
        "action",
      ],
    };
  },
  middleware: "authentication",
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getCompetitions();

  },
  created() {
    this.getTeams()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    callModalTeamsEdit(data) {
      this.$bvModal.show("teams_edit");
      this.modalData.team_id = data.team_id;
      this.modalData.ticketing_url = data.ticketing_url;
    },

    async getCompetitions() {
      try {
        const response = await Teams.getFootballCompetitions(); // Adjust according to your API
        this.competitions = response.data.competitions;
        this.competitionLength = this.competitions.length;

        if(this.competitionLength == 1){
          this.selectedCompetition = this.competitions[0].competition_id
          await this.getTeams()
        }

      } catch (error) {
        console.error("Error fetching competitions:", error);
      }
    },
    async getTeams() {
      try {
        this.toggleBusy();
        if (!this.selectedCompetition) {
          this.tableData = [];
          return;
        }
        const response = await Teams.getFootballCompetitions();
        const selectedCompetition = response.data.competitions.find(competition => competition.competition_id === this.selectedCompetition);
        if (!selectedCompetition) {
          console.error("Selected competition not found");
          return;
        }
        const teamsData = selectedCompetition.teams;
        this.tableData = teamsData.map(team => {
          const ticketingAttribute = team.team_attributes.find(attr => attr.attribute === "ticketing_url");
          const ticketingUrl = ticketingAttribute ? ticketingAttribute.value : "";
          const attribute = ticketingAttribute ? ticketingAttribute.attribute : "";


          return {
            team_id: team.team_id,
            team_logo: team.team_logo,
            team_code: team.team_code,
            team_country: team.team_country,
            team_name: team.team_name,
            ticketing_url: ticketingUrl,
            ticketingAttribute: attribute
          };
        });
      } catch (error) {
        this.error = error.response.data.error ? error.response.data.error : "";
      } finally {
        this.toggleBusy();
      }
    },


    removeAttribute(team_id, ticketingAttribute){

      Swal.fire({
        title: "Remove ticketing URL?",
        text: "This ticketing URL will be removed!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Remove!",
      }).then((result) => {
        if (result.value) {
          try {

            Teams.deleteTeamCompetitions(team_id, ticketingAttribute)
                .then((response) => {
                  this.successmsg('Ticketing URL removed successfully');
                  this.getTeams()
                })
                .catch(error => {
                  //console.log(error.response.data.error);
                  this.error = error.response.data.error ? error.response.data.error : "";
                  this.failedmsg( this.error )
                })
          }catch(error){
            this.error = error.response.data.error ? error.response.data.error : "";
          }
        }
      });
    },

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">

        <div class="table table-centered datatable dt-responsive nowrap table-card-list dataTable no-footer dtr-inline">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center fw-normal">
                  Show&nbsp;
                  <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
              >
                <label class="d-inline-flex align-items-center fw-normal">
                  Search:
                  <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
            <div class="row mb-4" v-if="competitionLength > 1">
              <div class="col-3">
                <h4 class="card-title">Select Competition</h4>
                <select v-model="selectedCompetition" class="form-control form-select" @change="getTeams">
                  <option value="" disabled>Select a competition</option>
                  <option v-for="competition in competitions" :key="competition.competition_id" :value="competition.competition_id">
                    {{ competition.competition_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!-- Table -->

          <b-table
              table-class="table table-responsive"
              :busy="isBusy"
              :items="tableData"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
              empty-text="No Data Found"
              @filtered="onFiltered"
          >
            <template v-slot:cell(team_logo)="data">
              <img :src="data.item.team_logo" class="rounded avatar-sm" />
            </template>

            <template v-slot:cell(action)="data">
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <a href="javascript:void(0);" class="px-2 text-primary" v-b-tooltip.hover title="Edit" @click="callModalTeamsEdit(data.item)">
                    <i class="uil uil-pen font-size-18"></i>
                  </a>
                </li>
                <li v-if="data.item.ticketing_url!=''" class="list-inline-item">
                  <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Delete" @click="removeAttribute(data.item.team_id, data.item.ticketingAttribute)">
                    <i class="uil uil-trash-alt font-size-18"></i>
                  </a>
                </li>
              </ul>
            </template>

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

          </b-table>

        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded">
                <!-- pagination -->
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODALS -->
    <modalEditTeamCompetition :team_id="modalData.team_id" :ticketing_url="modalData.ticketing_url" @onRefresh="getTeams"></modalEditTeamCompetition>
    <!-- END  MODALS -->

  </Layout>
</template>
