<script>
import Teams from '@/services/Teams'

export default {
  props: ['team_id','ticketing_url'],
  data() {
    return {
      ticketingURL: '',
      csrf_token: localStorage.getItem('csrf_token'),
      showModal: false,
      tryingToEdit: false,
    };
  },
  methods: {
    refreshTeams() {
      this.$emit('onRefresh') //event from parent
    },
    async editTeam() {
      this.tryingToEdit = true;

      await Teams.editTeamCompetitions( {
        team_id: this.team_id,
        ticketing_url: this.ticketingURL,
        csrf_token: this.csrf_token
      })
          .then(() => {
            this.successmsg();
            this.closeModal();
          })
          .catch(error => {
            //console.log(error.response.data.error);
            this.error = error.response.data.error ? error.response.data.error : "";
            this.failedmsg( this.error )
          })
          .finally(() => {
            this.refreshTeams()
            this.tryingToEdit = false;
          })
    },

    closeModal() {
      this.showModal = false;
    },

    setData(){
      this.ticketingURL = this.ticketing_url;
    },
  }
};
</script>



<template>
  <b-modal @shown="setData" v-model="showModal" id="teams_edit" title="Edit Team" title-class="font-18" centered>
    <form @submit.prevent="editTeam">
      <b-form-group label="Ticketing URL" label-for="formrow-firstname-input" class="mb-3">
        <b-form-input v-model="ticketingURL" type="text"  ></b-form-input>
      </b-form-group>
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="editTeam" :disabled="tryingToEdit">
        <b-spinner v-show="tryingToEdit" small></b-spinner>
        Change</b-button>
    </template>
  </b-modal>
</template>